import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";

// Customizable Area Start
import { getStorageData } from "../../../framework/src/Utilities"

export interface GetUserResponseSuccess {
    data: Data;
  }
  export interface Data {
    id: string;
    type: string;
    attributes: Attributes;
  }
  export interface Attributes {
    activated: boolean;
    country_code: string;
    email: string;
    full_phone_number: string;
    phone_number: string;
    type: string;
    full_name: string;
    created_at: string;
    updated_at: string;
    is_dealer_group: boolean;
    dealer_group_name?: null;
    role: string;
    profile_picture?: null;
    group_created_on?: null;
    is_deal_pending: boolean;
    deal_data?: null;
    solo_dealership_id: number;
  }
  
  interface GetUserError {
    errors:[
      {
        token:string;
      }
    ]
  }
export   interface TableDataAcceptedSp {
  id: string;
  customerLastName: string;
  dealership: string;
  states: string;
  dateReceived: string;
  dateAccepted: string;
  service: string;
  fees: string;
  payout: string;
  status: string;
}

export interface TableRows {
  id: string;
  customerLastName: string;
  dealership: string;
  states: string;
  dateCreated: string;
  dateAccepted?: string;
  service?: string;
  fees: string;
  serviceProvider: string;
  status: string;
}

export interface TablePendingDataRow {
  id: string;
  customerLastName: string;
  dealership: string;
  states: string;
  dateSent: string;
  service: string;
  fees: string;
  serviceProvider: string;
  status: string;
}

export interface TableDataDeletedorCompleted{
  id: string;
  customerLastName: string;
  dealership: string;
  states: string;
  dateCreated: string;
  dateCancelled?: string;
  service?: string;
  fees: string;
  serviceProvider: string;
  status: string;
}

export interface TableDraftDataRow {
  id: string;
  customerLastName: string;
  dealership: string;
  states: string;
  dateCreated: string;
  typeOfVehicle: string;
  applicationType: string;
}

export interface TablePendingDataSp {
  id: string;
  customerLastName: string;
  dealership: string;
  states: string;
  dateReceived: string;
  service: string;
  payout: string;
  status: string;
}


export interface DeletedDatatableSp {
  id: string;
  customerLastName: string;
  dealership: string;
  states: string;
  dateCreated: string;
  dateDeleted: string;
  service: string;
  fees: string;
  payout: string;
  status: string;
}
export interface RejectedOrExpiredData {
  id: string;
  customerLastName: string;
  dealership: string;
  states: string;
  dateCreated: string;
  dateRejectedOrExpired: string;
  service: string;
  payout: string;
  status: string; 
}
export interface ResponseDataBE {
  total_deals: number;
  ongoing: OngoingOrPendingHireOrDeletedOrCompletedOrDraft;
  pending_hire: OngoingOrPendingHireOrDeletedOrCompletedOrDraft;
  deleted: OngoingOrPendingHireOrDeletedOrCompletedOrDraft;
  completed: OngoingOrPendingHireOrDeletedOrCompletedOrDraft;
  draft: OngoingOrPendingHireOrDeletedOrCompletedOrDraft;
}
export interface OngoingOrPendingHireOrDeletedOrCompletedOrDraft {
  data?: (DataEntity)[] | null;
}
export interface DataEntity {
  id: string;
  type: string;
  attributes: Attributes;
}
export interface Attributes {
  id: number;
  updated_at: string;
  application_type: string;
  registration_type: string;
  vehicle_vin: string;
  vehicle_type: string;
  vehicle_year: string;
  vehicle_weight_empty: string;
  vehicle_weight_gross: string;
  sale_type: string;
  sold_as: string;
  msrp: string;
  tsp: string;
  seller_zip: string;
  owner_first_name: string;
  owner_last_name: string;
  purchaser_zip: string;
  first_owner?: null;
  second_owner?: null;
  make?: null;
  odometer?: null;
  body_type?: null;
  is_draft: boolean;
  deal_documents?: (null)[] | null;
  date_created: string;
  deal_status: string;
  from_state: string;
  to_state: string;
  dealership_detail: DealershipDetail;
  account: Account;
  deal_documents_ids?: (null)[] | null;
  fees_data?: null;
  service_provider?: null;
  service?: null;
  date_accepted?: null;
}
export interface DealershipDetail {
  data: Dataa;
}
export interface Dataa {
  id: string;
  type: string;
  attributes: Attributes1;
}
export interface Attributes1 {
  dealership_name: string;
  business_credentials: string;
  deal_type?: (string)[] | null;
  created_at: string;
  updated_at: string;
  dealership_users: number;
  addresses: Addresses;
  bank_information: BankInformation;
  dealership_logo?: null;
  w9_forms?: (null)[] | null;
}
export interface Addresses {
  data?: (DataEntity1)[] | null;
}
export interface DataEntity1 {
  id: string;
  type: string;
  attributes: Attributes2;
}
export interface Attributes2 {
  address: string;
  address_type: string;
  country: string;
  city: string;
  state: string;
  zipcode: string;
  zipcode_ext: string;
}
export interface BankInformation {
  data?: (null)[] | null;
}
export interface Account {
  data: Data1;
}
export interface Data1 {
  id: string;
  type: string;
  attributes: Attributes3;
}
export interface Attributes3 {
  activated: boolean;
  country_code: string;
  email: string;
  full_phone_number: string;
  phone_number: string;
  type: string;
  full_name: string;
  created_at: string;
  updated_at: string;
  is_dealer_group: boolean;
  dealer_group_name: string;
  role: string;
  profile_picture?: null;
  group_created_on: string;
  is_dealership_pending: boolean;
  dealership_data?: null;
  solo_dealership_id?: null;
  is_any_dealership: boolean;
  solo_dealership_data?: null;
  assigned_dealership_ids?: null;
}
// Customizable Area End

export const configJSON = require("./config");
export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  profilePhoto:string | null |undefined;
  tabvalue: number;
  searchValue: string,
  sortColumn: string,
  filterState: string,
  page: number,
  rowsPerPage:number,
  role:string,
  totalRecords: number,

  sortOption:string,
  totaldeals:number,
  loading: boolean,

  tableData: TableRows[],
  tablePendingData: TablePendingDataRow[],
  tableDataCompleted: TableDataDeletedorCompleted[],
  tableDataDeleted: TableDataDeletedorCompleted[],
  tableDataDraft: TableDraftDataRow[],

  tableDataAcceptedSp:TableDataAcceptedSp[],
  tablePendingDataSp: TablePendingDataSp[],
  deletedDatatableSp:DeletedDatatableSp[],
  tableRejectedorExpiredDataSp: RejectedOrExpiredData[]
  // Customizable Area End

}

interface SS {
  // Customizable Area Start
  id: any;
  // Customizable Area End
}

export default class AlldealsController extends BlockComponent<
  Props,
  S,
  SS
> {

  // Customizable Area Start
  changeNameApiCallId:string="";
  getuserDataApiCallId:string="";
  sendContactUsApiCallId:string="";
  getDealDataApiCallId:string="";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.CountryCodeMessage)
    ];
   
    this.state = {
      profilePhoto:'',
      tabvalue:0,
      searchValue: '',
      sortColumn: '',
      filterState: '',
      page:0,
      rowsPerPage:8,
      totalRecords: 0,
      role:'',

      sortOption:'',
      totaldeals:0,
      loading: true,

      tableDataAcceptedSp : [
        { id: "#203193", customerLastName: "Smith", dealership: "Luther Honda", states: "CA > NY", dateReceived: "5/10/2024", dateAccepted: "5/12/2024", service: "Runner", fees: "$2,010.13", payout: "$123", status: "In Progress" },
        { id: "#392051", customerLastName: "Arthur", dealership: "Luther Hyundai", states: "AZ > CA", dateReceived: "4/28/2024", dateAccepted: "5/12/2024", service: "Runner", fees: "$1,000.00", payout: "$123", status: "Dealer Flags Incomplete" },
        { id: "#234011", customerLastName: "Hoover", dealership: "Luther Honda", states: "GA > CA", dateReceived: "4/22/2024", dateAccepted: "5/12/2024", service: "Runner", fees: "$3,020.70", payout: "$123", status: "Completed" },
        { id: "#875023", customerLastName: "Cooper", dealership: "Luther Hyundai", states: "OH > NY", dateReceived: "4/20/2024", dateAccepted: "4/20/2024", service: "White Glove", fees: "$5,200.00", payout: "$123", status: "Issues Sent to Dealer" },
        { id: "#001231", customerLastName: "Davis", dealership: "Luther Honda", states: "FL > AZ", dateReceived: "4/18/2024", dateAccepted: "4/20/2024", service: "Full Service", fees: "$1,800.30", payout: "$123", status: "In Progress" },
        { id: "#456721", customerLastName: "Miller", dealership: "Luther Hyundai", states: "CA > OH", dateReceived: "4/16/2024", dateAccepted: "4/18/2024", service: "Runner", fees: "$8,014.13", payout: "$123", status: "Resolved, In Dealer Review" },
        { id: "#345631", customerLastName: "Jackson", dealership: "Luther Honda", states: "AZ > FL", dateReceived: "4/12/2024", dateAccepted: "4/15/2024", service: "Onsite", fees: "$4,400.00", payout: "$123", status: "Issues Sent to Dealer" },
    ],
    tableData : [],
    tablePendingData : [],
    tableDataCompleted:[],
    tableDataDeleted : [],
    tableDataDraft : [],
    tablePendingDataSp : [
      { id: "#457432", customerLastName: "Arthur", dealership: "Luther Honda", states: "AZ > CA", dateReceived: "4/29/2024", service: "Full Service", payout: "$1,000.00",  status: "Available" },
      { id: "#457432", customerLastName: "Arthur", dealership: "Luther Honda", states: "AZ > CA", dateReceived: "4/29/2024", service: "Full Service", payout: "$1,000.00", status: "Available" },
      { id: "#457432", customerLastName: "Arthur", dealership: "Luther Honda", states: "AZ > CA", dateReceived: "4/29/2024", service: "Full Service", payout: "$1,000.00",  status: "Available"},
      { id: "#305204", customerLastName: "Cooper", dealership: "Luther Honda", states: "CA > OH", dateReceived: "4/16/2024", service: "Runner", payout: "$8,014.13",  status: "Available" },
      { id: "#203193", customerLastName: "Hoover", dealership: "Luther Hyundai", states: "GA > CA", dateReceived: "4/22/2024", service: "Onsite", payout: "$3,020.70",  status: "Available"},
    ],
    deletedDatatableSp : [
      { id: "#203193", customerLastName: "Smith", dealership: "WheelsUp", states: "CA > NY", dateCreated: "5/10/2024", dateDeleted: "5/12/2024", service: "Runner", fees: "$2,010.13", payout: "$100.00", status: "Deleted" },
      { id: "#392051", customerLastName: "Arthur", dealership: "TurboWheelz", states: "AZ > CA", dateCreated: "4/28/2024", dateDeleted: "5/1/2024", service: "Full Service", fees: "$1,000.00", payout: "$120.00", status: "Deleted" },
      { id: "#234011", customerLastName: "Hoover", dealership: "SpeedZone", states: "GA > CA", dateCreated: "4/22/2024", dateDeleted: "4/28/2024", service: "Onsite", fees: "$3,020.70", payout: "$106.00", status: "Deleted" },
      { id: "#875023", customerLastName: "Cooper", dealership: "SpeedStreet", states: "OH > NY", dateCreated: "4/20/2024", dateDeleted: "4/20/2024", service: "White Glove", fees: "$5,200.00", payout: "$200.00", status: "Deleted" },
      { id: "#001231", customerLastName: "Davis", dealership: "RoadPulse", states: "FL > AZ", dateCreated: "4/18/2024", dateDeleted: "4/20/2024", service: "Full Service", fees: "$1,800.30", payout: "$180.00", status: "Deleted" },
    ],
    tableRejectedorExpiredDataSp : [
      { id: "#457432", customerLastName: "Arthur", dealership: "Luther Honda", states: "AZ > CA", dateCreated: "4/29/2024", dateRejectedOrExpired: "4/29/2024", service: "Full Service", payout: "$1,000.00",  status: "Rejected" },
      { id: "#457432", customerLastName: "Arthur", dealership: "Luther Honda", states: "AZ > CA", dateCreated: "4/29/2024",dateRejectedOrExpired: "4/29/2024", service: "Full Service", payout: "$1,000.00", status: "Expired" },
      { id: "#457432", customerLastName: "Arthur", dealership: "Luther Honda", states: "AZ > CA", dateCreated: "4/29/2024",dateRejectedOrExpired: "4/29/2024", service: "Full Service", payout: "$1,000.00",  status: "Rejected"},
      { id: "#305204", customerLastName: "Cooper", dealership: "Luther Honda", states: "CA > OH", dateCreated: "4/16/2024",dateRejectedOrExpired: "4/29/2024", service: "Runner", payout: "$8,014.13",  status: "Rejected" },
      { id: "#203193", customerLastName: "Hoover", dealership: "Luther Hyundai", states: "GA > CA", dateCreated: "4/22/2024",dateRejectedOrExpired: "4/29/2024", service: "Onsite", payout: "$3,020.70",  status: "Expired"},
    ],
    };

    // Customizable Area End
    runEngine.attachBuildingBlock(this, this.subScribedMessages);
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      if (apiRequestCallId && responseJson) {
        if(apiRequestCallId === this.getuserDataApiCallId){
            this.handleUserData(responseJson);
        }
        else if(apiRequestCallId===this.getDealDataApiCallId){
          this.handleDealdata(responseJson)
        }
      }
    } 

    // Customizable Area End
  }

  // Customizable Area Start

  handleDealdata=(responseJson:ResponseDataBE)=>{
    if(this.checkRole()){
      this.setState({totaldeals: responseJson.total_deals, loading: false})
      this.setOngoingdata(responseJson)
      this.setPendingData(responseJson)
      this.setDeleteddata(responseJson)
      this.setCompletedData(responseJson)
      this.setDraftdata(responseJson)

    }else if(this.state.role === ('service_provider' || 'service_provider_admin')){
      this.setState({
        totaldeals: responseJson.total_deals, loading: false,
      })
    }
  }

  convertToReadableFormat(input:string) {
    return input.split('_').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' '); 
  }

  setOngoingdata=(responseJson:ResponseDataBE)=>{
    if(responseJson.ongoing.data) {
      const transformedData = responseJson.ongoing.data.map((deal:any) => ({
        id: deal.id,
        customerLastName: deal.attributes.owner_last_name || "N/A",
        dealership: deal.attributes.dealership_detail?.data?.attributes?.dealership_name || "N/A",
        states: `${deal.attributes.from_state} > ${deal.attributes.to_state}`,
        dateCreated: new Date(deal.attributes.date_created).toLocaleDateString(),
        dateAccepted: deal.attributes.date_accepted ? new Date(deal.attributes.date_accepted).toLocaleDateString() : "-",
        service: deal.attributes.service || "-",
        fees: deal.attributes.fees_data?.total_fee || "N/A",
        serviceProvider: deal.attributes.service_provider || "-",
        status: this.convertToReadableFormat(deal.attributes.deal_status) 
      }));
      this.setState({tableData: transformedData})
  }
  }

  setPendingData = (responseJson:ResponseDataBE) => {
    if(responseJson.pending_hire.data) {
      const transformedPendingdata = responseJson.pending_hire.data.map((deal:any) => ({
      id: deal.attributes.id,
      customerLastName: deal.attributes.owner_last_name,
      dealership: deal.attributes.dealership_detail.data.attributes.dealership_name,
      states: `${deal.attributes.from_state} > ${deal.attributes.to_state}`,
      dateSent: new Date(deal.attributes.date_created).toLocaleDateString(), 
      service: deal.attributes.service,
      fees: deal.attributes.fees_data ? '$123' : "-", 
      serviceProvider: deal.attributes.service_provider || "N/A",
      status: this.convertToReadableFormat(deal.attributes.deal_status) 
    }));
    this.setState({tablePendingData: transformedPendingdata})
  }
  };

  setDeleteddata = (responseJson:ResponseDataBE)=>{
    if(responseJson.deleted.data){
    const transformedDeleteddata = responseJson.deleted.data.map((deal:any) => ({
      id: deal.attributes.id,
      customerLastName: deal.attributes.owner_last_name,
      dealership: deal.attributes.dealership_detail.data.attributes.dealership_name,
      states: `${deal.attributes.from_state} > ${deal.attributes.to_state}`,
      dateCreated: new Date(deal.attributes.date_created).toLocaleDateString(), 
      dateCancelled: new Date(deal.attributes.date_cancelled).toLocaleDateString(),
      service: deal.attributes.service,
      fees: deal.attributes.fees_data ? '$123' : "-", 
      serviceProvider: deal.attributes.service_provider || "N/A",
      status: 'Deleted'
    }));
    this.setState({tableDataDeleted: transformedDeleteddata})
  }
  };

  setCompletedData=(responseJson:ResponseDataBE)=>{
    if(responseJson.completed.data){
    const transformedCompletedddata = responseJson.completed.data.map((deal:any) => ({
      id: deal.attributes.id,
      customerLastName: deal.attributes.owner_last_name,
      dealership: deal.attributes.dealership_detail.data.attributes.dealership_name,
      states: `${deal.attributes.from_state} > ${deal.attributes.to_state}`,
      dateCreated: new Date(deal.attributes.date_created).toLocaleDateString(), 
      dateCompleted: new Date(deal.attributes.date_cancelled).toLocaleDateString(),
      service: deal.attributes.service,
      fees: deal.attributes.fees_data ? '$123' : "-", 
      serviceProvider: deal.attributes.service_provider || "N/A",
      status: 'Completed'
    }));
    this.setState({tableDataCompleted: transformedCompletedddata})
  }
  }

  setDraftdata=(responseJson:ResponseDataBE)=>{
    if(responseJson.draft.data){
    const transformedDraftddata = responseJson.draft.data.map((deal:any) => ({
      id: deal.attributes.id,
      customerLastName: deal.attributes.owner_last_name,
      dealership: deal.attributes.dealership_detail.data.attributes.dealership_name,
      states: `${deal.attributes.from_state} > ${deal.attributes.to_state}`,
      dateCreated: new Date(deal.attributes.date_created).toLocaleDateString(), 
      typeOfVehicle: deal.attributes.vehicle_type,
      applicationType: deal.attributes.application_type,
    }));
    this.setState({tableDataDraft: transformedDraftddata})
  }
  }

  handleUserData=(responseJson:GetUserResponseSuccess & GetUserError)=>{
    if(!responseJson.errors){
      this.setState({role:responseJson.data.attributes.role})
    }
  }


  handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    this.setState({ 
        tabvalue: newValue, 
        searchValue: '', 
        sortColumn: '',
        filterState: '',
        sortOption:''
    });
    if(this.state.page!==0){
      this.handleChangePage({}, 0);
    }
};

  a11yProps(index: number) {
    return {
      id: `full-width-tab-${index}`,
      'aria-controls': `full-width-tabpanel-${index}`,
    };
  }

  handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ searchValue: event.target.value });
};


handleSort = (event: React.ChangeEvent<{ name?: string; value: unknown }>) => {
  this.setState({ sortOption: event.target.value as string });
};

checkDisablitiy = (status:string, field:string)=>{
    if(status==='Rejected' && field==='withdraw'){
        return true
    }
    else  if(status==='Rejected' && field==='hirenew'){
        return false
    }
    else  if(status==='No Response' && field==='withdraw'){
        return false
    }
    else return true
}


handleChangePage = (event: unknown, newPage: number) => {
  this.setState({ page: newPage }, () => {
    this.setState({loading:true})
    this.getDealsFromBe(this.state.page, this.state.rowsPerPage);
  });
  };

  handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    this.setState({
      rowsPerPage: parseInt(event.target.value, 8),
      page: 0,
    }, () => {
      this.setState({loading:true})
      this.getDealsFromBe(this.state.page, this.state.rowsPerPage);
    });
  };

  async componentDidMount(){
    this.getUserDataFromBe();
    this.getDealsFromBe(this.state.page, this.state.rowsPerPage);
  }

  getDealsFromBe=async(page:number, rowperpage:number)=>{
    const authtoken = await getStorageData('authToken')
    const Webheader = {
      "Content-Type": 'application/json',
      "token": authtoken
    };
    const WebrequestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getDealDataApiCallId = WebrequestMessage.messageId;   
    WebrequestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `/bx_block_posts/deals?page=${page + 1}&per_page=${rowperpage}`
    );
    WebrequestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(Webheader)
    );
    WebrequestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      'GET'
    );
    runEngine.sendMessage(WebrequestMessage.id, WebrequestMessage);
  }

  getUserDataFromBe=async()=>{
    const newtoken = await getStorageData('authToken')
    const Webheader = {
      "Content-Type": 'application/json',
      "token": newtoken
    };
    const WebrequestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getuserDataApiCallId = WebrequestMessage.messageId;   
    WebrequestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
     'account_block/accounts/get_user'
    );
    WebrequestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(Webheader)
    );
    WebrequestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      'GET'
    );
    runEngine.sendMessage(WebrequestMessage.id, WebrequestMessage);
  }

checkRole = ()=>{
  return (this.state.role === 'dealer' || this.state.role === 'dealer_admin')
}

 getSortOptions = (tabIndex : number) => {
  if(this.checkRole()){
    return ['Deal Id', "Customer's last name", 'Dealership', 'Date Sent'];
  }else{
  switch(tabIndex) {
    case 0: 
      return ['Dealership (A-Z)', 'Most Recent (Date Received)', 'Payout (High to Low)'];
    case 1: 
      return ['Dealership (A-Z)', 'Most Recent (Date Received)', 'Payout (High to Low)'];
    case 2: 
      return ['Dealership (A-Z)', 'Most Recent (Date Rejected)', 'Payout (High to Low)'];
    case 3: 
      return ['Dealership (A-Z)', 'Most Recent (Date Deleted)', 'Payout (High to Low)'];
    default:
      return [];
  }
  }
}
  // Customizable Area End

}